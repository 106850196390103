import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import purchaseOrderService from '@service/admin/masterService/purchaseOrderService';
import {
    savePoSuccess, savePoError,
    listPoSuccess, listPoError,
    fetchPoByIdSuccess, fetchPoByIdError,
    listPurchaseRequestPoSuccess, listPurchaseRequestPoError,
    listVendorPurchaseRequestSuccess, listVendorPurchaseRequestError,
    getPrItemsForPoSuccess, getPrItemsForPoError,
    updatePoSuccess, updatePoError,
} from './action';
import { stopLoader, startLoader } from '@store/common/loader/action'


function* savePo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(purchaseOrderService.savePo, payload);
        yield put(savePoSuccess(data));
    } catch (error) {
        yield put(savePoError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* listPo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(purchaseOrderService.listPo, payload);
        Array.isArray(data) ?
            yield put(listPoSuccess(data)) :
            yield put(listPoSuccess([]));
    } catch (error) {
        yield put(listPoError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchPoById({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(purchaseOrderService.fetchPoById, payload);
        if (Object.keys(data).length > 0) {
            let pr_no = data.pr_no ? data.pr_no.split(',') : []
            let pr_id = data.pr_id ? data.pr_id.split(',') : []
            let pr_items = []
            for (let i = 0; i < pr_id.length; i++) {
                pr_items[i] = {
                    requisition_no: pr_no[i],
                    id: pr_id[i],
                    item_spec: data.item_details.filter(item => item.requisition_id === pr_id[i])
                }
            }
            let new_items = data.item_details.filter(item => !item.requisition_id)
            yield put(fetchPoByIdSuccess({ ...data, pr_items: pr_items, new_items: new_items.length > 0 ? new_items : [], allPoItems: data.item_details }));
        }
    } catch (error) {
        yield put(fetchPoByIdError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* listPurchaseRequestPo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(purchaseOrderService.listPurchaseRequestPo, payload);
        yield put(listPurchaseRequestPoSuccess(data));
    } catch (error) {
        yield put(listPurchaseRequestPoError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* listVendorPurchaseRequest({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(purchaseOrderService.listVendorPurchaseRequest, payload);
        yield put(listVendorPurchaseRequestSuccess(data));
    } catch (error) {
        yield put(listVendorPurchaseRequestError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* getPrItemsForPo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(purchaseOrderService.getPrItemsForPo, payload);
        yield put(getPrItemsForPoSuccess(data));
    } catch (error) {
        yield put(getPrItemsForPoError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* updatePo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(purchaseOrderService.updatePo, payload);
        yield put(updatePoSuccess(data));
    } catch (error) {
        yield put(updatePoError(error));
    } finally {
        yield put(stopLoader());
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actionTypes.SAVE_PO, savePo)]);
    yield all([takeEvery(actionTypes.LIST_PO, listPo)]);
    yield all([takeEvery(actionTypes.FETCH_PO_DATA_BY_ID, fetchPoById)]);
    yield all([takeEvery(actionTypes.LIST_PR_FOR_PO, listPurchaseRequestPo)]);
    yield all([takeEvery(actionTypes.LIST_VENDOR_PR_FOR_PO, listVendorPurchaseRequest)]);
    yield all([takeEvery(actionTypes.GET_PR_ITEMS_FOR_PO, getPrItemsForPo)]);
    yield all([takeEvery(actionTypes.UPDATE_PO, updatePo)]);
}