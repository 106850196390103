
import { actionTypes } from './action';

export const initialState = {
    listItemvalues: [],
    saveManufactureStatus: "",
    listManufactureValues: [],
    getCompositeIdValues: [],
    deletemanufacturingItemStatus: "",
    updatemanufacturingItemStatus: "",

    saveMritemStockStatus: '',
    listMritemStock: [],
    updateMritemStockStatus: '',
    deleteMritemStockStatus: '',

    companyList: [],
    contactList: [],
    salesmanList: [],
    saveStatus: '',
    listMirStatus: [],
    updateMirStatus: '',
    getManufactureItemList: [],
    deleteMirStatus: '',

}
const manufacturingItemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LIST_ITEMS:
            return { ...state, listItemvalues: [] }
        case actionTypes.LIST_ITEMS_SUCCESS:
            return { ...state, listItemvalues: action.payload }
        case actionTypes.LIST_ITEMS_ERROR:
            return { ...state, listItemvalues: [] }

        case actionTypes.SAVE_MANUFACTURE_ITEMS:
            return { ...state, saveManufactureStatus: "" }
        case actionTypes.SAVE_MANUFACTURE_ITEMS_SUCCESS:
            return { ...state, saveManufactureStatus: action.payload }
        case actionTypes.SAVE_MANUFACTURE_ITEMS_ERROR:
            return { ...state, saveManufactureStatus: "" }

        case actionTypes.LIST_MANUFACTURE_ITEMS:
            return { ...state, listManufactureValues: [] }
        case actionTypes.LIST_MANUFACTURE_ITEMS_SUCCESS:
            return { ...state, listManufactureValues: action.payload }
        case actionTypes.LIST_MANUFACTURE_ITEMS_ERROR:
            return { ...state, listManufactureValues: [] }

        case actionTypes.GET_COMPOSITE_ID:
            return { ...state, getCompositeIdValues: [] }
        case actionTypes.GET_COMPOSITE_ID_SUCCESS:
            return { ...state, getCompositeIdValues: action.payload }
        case actionTypes.GET_COMPOSITE_ID_ERROR:
            return { ...state, getCompositeIdValues: [] }

        case actionTypes.DELETE_MANUFACTURING_ITEM:
            return { ...state, deletemanufacturingItem: "" }
        case actionTypes.DELETE_MANUFACTURING_ITEM_SUCCESS:
            return { ...state, deletemanufacturingItem: action.payload }
        case actionTypes.DELETE_MANUFACTURING_ITEM_ERROR:
            return { ...state, deletemanufacturingItem: "" }

        case actionTypes.UPDATE_MANUFACTURING_ITEM:
            return { ...state, updatemanufacturingItemStatus: "" }
        case actionTypes.UPDATE_MANUFACTURING_ITEM_SUCCESS:
            return { ...state, updatemanufacturingItemStatus: action.payload }
        case actionTypes.UPDATE_MANUFACTURING_ITEM_ERROR:
            return { ...state, updatemanufacturingItemStatus: "" }

        case actionTypes.SAVE_MRITEM_STOCK:
            return { ...state, saveMritemStockStatus: '' }
        case actionTypes.SAVE_MRITEM_STOCK_SUCCESS:
            return { ...state, saveMritemStockStatus: action.payload }
        case actionTypes.SAVE_MRITEM_STOCK_ERROR:
            return { ...state, saveMritemStockStatus: '' }

        case actionTypes.LIST_MRITEM_STOCK:
            return { ...state, listMritemStock: [] }
        case actionTypes.LIST_MRITEM_STOCK_SUCCESS:
            return { ...state, listMritemStock: action.payload }
        case actionTypes.LIST_MRITEM_STOCK_ERROR:
            return { ...state, listMritemStock: [] }

        case actionTypes.UPDATE_MRITEM_STOCK:
            return { ...state, updateMritemStockStatus: '' }
        case actionTypes.UPDATE_MRITEM_STOCK_SUCCESS:
            return { ...state, updateMritemStockStatus: action.payload }
        case actionTypes.UPDATE_MRITEM_STOCK_ERROR:
            return { ...state, updateMritemStockStatus: '' }

        case actionTypes.DELETE_MRITEM_STOCK:
            return { ...state, deleteMritemStockStatus: '' }
        case actionTypes.DELETE_MRITEM_STOCK_SUCCESS:
            return { ...state, deleteMritemStockStatus: action.payload }
        case actionTypes.DELETE_MRITEM_STOCK_ERROR:
            return { ...state, deleteMritemStockStatus: '' }

            case actionTypes.LIST_COMPANY:
            return { ...state, companyList: [] }
        case actionTypes.LIST_COMPANY_SUCCESS:
            return { ...state, companyList: action.payload }
        case actionTypes.LIST_COMPANY_ERROR:
            return { ...state, companyList: [] }

        case actionTypes.LIST_CONTACT:
            return { ...state, contactList: [] }
        case actionTypes.LIST_CONTACT_SUCCESS:
            return { ...state, contactList: action.payload }
        case actionTypes.LIST_CONTACT_ERROR:
            return { ...state, contactList: [] }

        case actionTypes.LIST_SALESMAN:
            return { ...state, salesmanList: [] }
        case actionTypes.LIST_SALESMAN_SUCCESS:
            return { ...state, salesmanList: action.payload }
        case actionTypes.LIST_SALESMAN_ERROR:
            return { ...state, salesmanList: [] }

        case actionTypes.SAVE_MANUFACTURING_REQUEST:
            return { ...state, saveStatus: '' }
        case actionTypes.SAVE_MANUFACTURING_REQUEST_SUCCESS:
            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_MANUFACTURING_REQUEST_ERROR:
            return { ...state, saveStatus: '' }

        case actionTypes.LIST_MANUFACTURING_REQUEST:
            return { ...state, listMirStatus: [] }
        case actionTypes.LIST_MANUFACTURING_REQUEST_SUCCESS:
            return { ...state, listMirStatus: action.payload }
        case actionTypes.LIST_MANUFACTURING_REQUEST_ERROR:
            return { ...state, listMirStatus: [] }

        case actionTypes.UPDATE_MANUFACTURING_REQUEST:
            return { ...state, updateMirStatus: '' }
        case actionTypes.UPDATE_MANUFACTURING_REQUEST_SUCCESS:
            return { ...state, updateMirStatus: action.payload }
        case actionTypes.UPDATE_MANUFACTURING_REQUEST_ERROR:
            return { ...state, updateMirStatus: '' }

        case actionTypes.GET_MANUFACTURE_ITEMS:
            return { ...state, getManufactureItemList: [] }
        case actionTypes.GET_MANUFACTURE_ITEMS_SUCCESS:
            return { ...state, getManufactureItemList: action.payload }
        case actionTypes.GET_MANUFACTURE_ITEMS_ERROR:
            return { ...state, getManufactureItemList: [] }

        case actionTypes.DELETE_MANUFACTURE_ITEMS:
            return { ...state, deleteMirStatus: '' }
        case actionTypes.DELETE_MANUFACTURE_ITEMS_SUCCESS:
            return { ...state, deleteMirStatus: action.payload }
        case actionTypes.DELETE_MANUFACTURE_ITEMS_ERROR:
            return { ...state, deleteMirStatus: '' }

        default:
            return state
    }
}
export default manufacturingItemsReducer