import { actionTypes } from './action';

export const initialState = {
    saveEqMasterStatus: '',
    listEqMaster: [],
    updateEqMasterStatus: '',
    deleteEqMasterStatus: '',
}
const equipmentReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_EQUIPMENT_MASTER:
            return { ...state, saveEqMasterStatus: '' }
        case actionTypes.SAVE_EQUIPMENT_MASTER_SUCCESS:
            return { ...state, saveEqMasterStatus: action.payload }
        case actionTypes.SAVE_EQUIPMENT_MASTER_ERROR:
            return { ...state, saveEqMasterStatus: '' }

        case actionTypes.LIST_EQUIPMENT_MASTER:
            return { ...state, listEqMaster: [] }
        case actionTypes.LIST_EQUIPMENT_MASTER_SUCCESS:
            return { ...state, listEqMaster: action.payload }
        case actionTypes.LIST_EQUIPMENT_MASTER_ERROR:
            return { ...state, listEqMaster: [] }

        case actionTypes.UPDATE_EQUIPMENT_MASTER:
            return { ...state, updateEqMasterStatus: '' }
        case actionTypes.UPDATE_EQUIPMENT_MASTER_SUCCESS:
            return { ...state, updateEqMasterStatus: action.payload }
        case actionTypes.UPDATE_EQUIPMENT_MASTER_ERROR:
            return { ...state, updateEqMasterStatus: '' }

        case actionTypes.DELETE_EQUIPMENT_MASTER:
            return { ...state, deleteEqMasterStatus: '' }
        case actionTypes.DELETE_EQUIPMENT_MASTER_SUCCESS:
            return { ...state, deleteEqMasterStatus: action.payload }
        case actionTypes.DELETE_EQUIPMENT_MASTER_ERROR:
            return { ...state, deleteEqMasterStatus: '' }

        default:
            return state
    }
}
export default equipmentReducer