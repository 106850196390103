import { actionTypes } from './action';


export const initialState = {
    departmentList: [],
    designationList: [],
    roleList: [],
    saveStatus: '',
    deleteStatus: '',
    updateStatus: '',
    listJobValues: [],
    userList: [],
    inviteStatus: '',
    userFilterData: [],
    userData: {},
    remunerationData: {},
    insertRemunerationStatus:'',
    validationStatus:''
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.JOB_DEPARTMENT:
            return { ...state, departmentList: [] }
        case actionTypes.JOB_DEPARTMENT_SUCCESS:
            return { ...state, departmentList: action.payload }
        case actionTypes.JOB_DEPARTMENT_ERROR:
            return { ...state, departmentList: [] }

        case actionTypes.JOB_DESIGNATION:
            return { ...state, designationList: [] }
        case actionTypes.JOB_DESIGNATION_SUCCESS:
            return { ...state, designationList: action.payload }
        case actionTypes.JOB_DESIGNATION_ERROR:
            return { ...state, designationList: [] }

        case actionTypes.JOB_ROLES:
            return { ...state, roleList: [] }
        case actionTypes.JOB_ROLES_SUCCESS:
            return { ...state, roleList: action.payload }
        case actionTypes.JOB_ROLES_ERROR:
            return { ...state, roleList: [] }

        case actionTypes.SAVE_USER:
            return { ...state, saveStatus: '' }
        case actionTypes.SAVE_USER_SUCCESS:
            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_USER_ERROR:
            return { ...state, saveStatus: 'error' }

        case actionTypes.UPDATE_USER:
            return { ...state, updateStatus: '' }
        case actionTypes.UPDATE_USER_SUCCESS:
            return { ...state, updateStatus: action.payload }
        case actionTypes.UPDATE_USER_ERROR:
            return { ...state, updateStatus: 'error' }

        case actionTypes.DELETE_USER:
            return { ...state, deleteStatus: '' }
        case actionTypes.DELETE_USER_SUCCESS:
            return { ...state, deleteStatus: action.payload }
        case actionTypes.DELETE_USER_ERROR:
            return { ...state, deleteStatus: 'error' }

        case actionTypes.LIST_JOB:
            return { ...state, listJobValues: [], }
        case actionTypes.LIST_JOB_SUCCESS:
            return { ...state, listJobValues: action.payload }
        case actionTypes.LIST_JOB_ERROR:
            return { ...state, listJobValues: [] }

        case actionTypes.LIST_USER:
            return { ...state, userList: [], updateStatus: '', saveStatus: '', deleteStatus: '' }
        case actionTypes.LIST_USER_SUCCESS:
            return { ...state, userList: action.payload }
        case actionTypes.LIST_USER_ERROR:
            return { ...state, userList: [] }

        case actionTypes.SEND_INVITATION:
            return { ...state, inviteStatus: '' }
        case actionTypes.SEND_INVITATION_SUCCESS:
            return { ...state, inviteStatus: action.payload }
        case actionTypes.SEND_INVITATION_ERROR:
            return { ...state, inviteStatus: 'error' }

        case actionTypes.USER_FILTER:
            return { ...state, userFilterData: [] }
        case actionTypes.USER_FILTER_SUCCESS:
            return { ...state, userFilterData: action.payload }
        case actionTypes.USER_FILTER_ERROR:
            return { ...state, userFilterData: [] }

        case actionTypes.GET_USER_DETAILS:
            return { ...state, userData: {} }
        case actionTypes.GET_USER_DETAILS_SUCCESS:
            return { ...state, userData: action.payload }
        case actionTypes.GET_USER_DETAILS_ERROR:
            return { ...state, userData: {} }

        case "GET_REMUNERATION_DETAILS":
            return { ...state, remunerationData: {} }
        case "GET_REMUNERATION_DETAILS_SUCCESS":
            return { ...state, remunerationData: action.payload }
        case "GET_REMUNERATION_DETAILS_ERROR":
            return { ...state, remunerationData: {} }

        case "INSERT_REMUNERATION_PACKAGE":
            return { ...state, insertRemunerationStatus: '' }
        case "INSERT_REMUNERATION_PACKAGE_SUCCESS":
            return { ...state, insertRemunerationStatus: action.payload }
        case "INSERT_REMUNERATION_PACKAGE_ERROR":
            return { ...state, insertRemunerationStatus: '' }

        case "EMAIL_PHONE_VALIDATION":
            return { ...state, validationStatus: '' }
        case "EMAIL_PHONE_VALIDATION_SUCCESS":
            return { ...state, validationStatus: action.payload }
        case "EMAIL_PHONE_VALIDATION_ERROR":
            return { ...state, validationStatus: '' }

        default:
            return state
    }
}

export default userReducer
