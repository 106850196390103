
//LIST DESIGNATION
export const FetchDesignation = (payload) => {
    return {
        type: 'FETCH_DESIGNATION',
        payload
    };
};
export const FetchDesignationSuccess = (payload) => {
    return {
        type: 'FETCH_DESIGNATION_SUCCESS',
        payload
    };
};
export const FetchDesignationError = (payload) => {
    return {
        type: 'FETCH_DESIGNATION_ERROR',
        payload
    };
};

// ADD DESIGNATION

export const AddDesignation = (payload) => {
    return {
        type: 'ADD_DESIGNATION',
        payload
    };
};
export const AddDesignationSuccess = (payload) => {
    return {
        type: 'ADD_DESIGNATION_SUCCESS',
        payload
    };
};
export const AddDesignationError = (payload) => {
    return {
        type: 'ADD_DESIGNATION_ERROR',
        payload
    };
};

// DELETE DESIGNATION

export const DeleteDesignation = (payload) => {
    return {
        type: 'DELETE_DESIGNATION',
        payload
    };
};
export const DeleteDesignationSuccess = (payload) => {
    return {
        type: 'DELETE_DESIGNATION_SUCCESS',
        payload
    };
};
export const DeleteDesignationError = (payload) => {
    return {
        type: 'DELETE_DESIGNATION_ERROR',
        payload
    };
};

// EDIT DESIGNATION

export const EditDesignation = (payload) => {
    return {
        type: 'EDIT_DESIGNATION',
        payload
    };
};
export const EditDesignationSuccess = (payload) => {
    return {
        type: 'EDIT_DESIGNATION_SUCCESS',
        payload
    };
};
export const EditDesignationError = (payload) => {
    return {
        type: 'EDIT_DESIGNATION_ERROR',
        payload
    };
};

// UPDATE DESIGNATION

export const UpdateDesignation = (payload) => {
    return {
        type: 'UPDATE_DESIGNATION',
        payload
    };
};
export const UpdateDesignationSuccess = (payload) => {
    return {
        type: 'UPDATE_DESIGNATION_SUCCESS',
        payload
    };
};
export const UpdateDesignationError = (payload) => {
    return {
        type: 'UPDATE_DESIGNATION_ERROR',
        payload
    };
};


export const AddDesignationRating = (payload) => {
    return {
        type: 'ADD_DESIGNATION_RATING',
        payload
    };
};
export const AddDesignationRatingSuccess = (payload) => {
    return {
        type: 'ADD_DESIGNATION_RATING_SUCCESS',
        payload
    };
};
export const AddDesignationRatingError = (payload) => {
    return {
        type: 'ADD_DESIGNATION_RATING_ERROR',
        payload
    };
};

export const UpdateDesignationRating = (payload) => {
    return {
        type: 'UPDATE_DESIGNATION_RATING',
        payload
    };
};
export const UpdateDesignationRatingSuccess = (payload) => {
    return {
        type: 'UPDATE_DESIGNATION_RATING_SUCCESS',
        payload
    };
};
export const UpdateDesignationRatingError = (payload) => {
    return {
        type: 'UPDATE_DESIGNATION_RATING_ERROR',
        payload
    };
};