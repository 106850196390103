import ApiClient from './ApiClient'


class authenticationService {
    constructor(callback) {
        this.callback = callback
    }

    async handleLogin(payload) {
        const response = await ApiClient.post(
            'authentication/authenticate',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async handleSignUp(payload) {
        const response = await ApiClient.post(
            'OnboardProcess/sign_up',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async verifyEmailAuth(payload) {
        const response = await ApiClient.post(
            'OnboardProcess/get_details_by_key',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async setCredential(payload) {
        const response = await ApiClient.post(
            'OnboardProcess/OnboardActivation',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async verifyUserEmailAuth(payload) {
        const response = await ApiClient.post(
            'Crm/User/get_details_by_key_user',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async getMenuLists(payload) {
        const response = await ApiClient.post(
            'authentication/create_menu',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
}

export default new authenticationService();