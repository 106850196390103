import { takeEvery, put, call, all } from 'redux-saga/effects';
import categoryService from '@service/admin/masterService/categoryService';
import {
    actionTypes,
    listCategorySuccess, listCategoryError,
    listSubCategorySuccess, listSubCategoryError,
    saveCategorySuccess, saveCategoryError,
    listAllCategorySuccess, listAllCategoryError,
    deleteCategorySuccess, deleteCategoryError,
    updateCategorySuccess, updateCategoryError,
} from './action';


function* saveCategory({ payload }) {
    try {
        const data = yield call(categoryService.saveCategory, payload);

        yield put(saveCategorySuccess(data));
    } catch (error) {
        yield put(saveCategoryError(error));
    } finally {
    }
}

function* removeCategory({ payload }) {
    try {
        const data = yield call(categoryService.removeCategory, payload);

        yield put(deleteCategorySuccess(data));
    } catch (error) {
        yield put(deleteCategoryError(error));
    }
}

function* updateCategory({ payload }) {
    try {
        const data = yield call(categoryService.updateCategory, payload);

        yield put(updateCategorySuccess(data));
    } catch (error) {
        yield put(updateCategoryError(error));
    }
}

function* listCategory({ payload }) {
    try {
        const data = yield call(categoryService.listCategory, payload);
        Array.isArray(data) ?
            yield put(listCategorySuccess(data)) :
            yield put(listCategorySuccess([]))
    } catch (error) {
        yield put(listCategoryError(error));
    } finally {
    }
}

function* listSubCategory({ payload }) {
    try {
        const data = yield call(categoryService.listSubCategory, payload);
        Array.isArray(data) ?
            yield put(listSubCategorySuccess(data)) :
            yield put(listSubCategorySuccess([]))
    } catch (error) {
        yield put(listSubCategoryError(error));
    } finally {
    }
}

function* listAllCategories({ payload }) {
    try {
        const data = yield call(categoryService.listAllCategories, payload);
        Array.isArray(data) ?
            yield put(listAllCategorySuccess(data)) :
            yield put(listAllCategorySuccess([]))
    } catch (error) {
        yield put(listAllCategoryError(error));
    } finally {
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.SAVE_CATEGORY, saveCategory)]);
    yield all([takeEvery(actionTypes.LIST_CATEGORY, listCategory)]);
    yield all([takeEvery(actionTypes.LIST_SUBCATEGORY, listSubCategory)]);
    yield all([takeEvery(actionTypes.LIST_ALL_CATEGORY, listAllCategories)]);
    yield all([takeEvery(actionTypes.DELETE_CATEGORY, removeCategory)]);
    yield all([takeEvery(actionTypes.UPDATE_CATEGORY, updateCategory)]);

}