import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import equipmentMasterService from '@service/admin/masterService/equipmentMasterService';
import {
  saveEquipmentMasterSuccess, saveEquipmentMasterError,
  listEquipmentMasterSuccess, listEquipmentMasterError,
  updateEquipmentMasterSuccess, updateEquipmentMasterError,
  deleteEquipmentMasterSuccess, deleteEquipmentMasterError,
} from './action';

function* saveEquipmentMaster({ payload }) {
  try {
    const data = yield call(equipmentMasterService.saveEquipmentMaster, payload);

    yield put(saveEquipmentMasterSuccess(data));
  } catch (error) {
    yield put(saveEquipmentMasterError(error));
  } finally {
  }
}

function* listEquipmentMaster({ payload }) {
  try {
    const data = yield call(equipmentMasterService.listEquipmentMaster, payload);

    yield put(listEquipmentMasterSuccess(data));
  } catch (error) {
    yield put(listEquipmentMasterError(error));
  } finally {
  }
}

function* updateEquipmentMaster({ payload }) {
  try {
    const data = yield call(equipmentMasterService.updateEquipmentMaster, payload);

    yield put(updateEquipmentMasterSuccess(data));
  } catch (error) {
    yield put(updateEquipmentMasterError(error));
  } finally {
  }
}

function* deleteEquipmentMaster({ payload }) {
  try {
    const data = yield call(equipmentMasterService.deleteEquipmentMaster, payload);

    yield put(deleteEquipmentMasterSuccess(data));
  } catch (error) {
    yield put(deleteEquipmentMasterError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_EQUIPMENT_MASTER, saveEquipmentMaster)]);
  yield all([takeEvery(actionTypes.LIST_EQUIPMENT_MASTER, listEquipmentMaster)]);
  yield all([takeEvery(actionTypes.UPDATE_EQUIPMENT_MASTER, updateEquipmentMaster)]);
  yield all([takeEvery(actionTypes.DELETE_EQUIPMENT_MASTER, deleteEquipmentMaster)]);

}