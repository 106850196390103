import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import manufactureService from '@service/admin/masterService/manufactureservice';
import {
  listItemsSuccess, listItemsError,
  saveManufactureItemsSuccess, saveManufactureItemsError,
  listManufactureItemsSuccess, listManufactureItemsError,
  getCompositeIdSuccess, getCompositeIdError,
  deletemanufacturingItemSuccess, deletemanufacturingItemError,
  updatemanufacturingItemSuccess, updatemanufacturingItemError,
  saveMrItemStockSuccess, saveMrItemStockError,
  listMrItemStockSuccess, listMrItemStockError,
  updateMrItemStockSuccess, updateMrItemStockError,
  deleteMrItemStockSuccess, deleteMrItemStockError,
  listCompanySuccess, listCompanyError,
  listContactSuccess, listContactError,
  listSalesmanSuccess, listSalesmanError,
  saveManufacturingRequestSuccess, saveManufacturingRequestError,
  listManufacturingRequestSuccess, listManufacturingRequestError,
  updateManufacturingRequestSuccess, updateManufacturingRequestError,
  getManufactureItemSuccess, getManufactureItemError,
  deleteManufactureItemSuccess, deleteManufactureItemError

} from './action';

function* listItems({ payload }) {
  try {
    const data = yield call(manufactureService.listItems, payload);

    yield put(listItemsSuccess(data));
  } catch (error) {
    yield put(listItemsError(error));
  } finally {
  }
}

function* saveManufactureItems({ payload }) {
  try {
    const data = yield call(manufactureService.saveManufactureItems, payload);

    yield put(saveManufactureItemsSuccess(data));
  } catch (error) {
    yield put(saveManufactureItemsError(error));
  } finally {
  }
}

function* listManufactureItems({ payload }) {
  try {
    const data = yield call(manufactureService.listManufactureItems, payload);
    Array.isArray(data) ?
    yield put(listManufactureItemsSuccess(data)) : yield put(listManufactureItemsError([]));
  } catch (error) {
    yield put(listManufactureItemsError(error));
  } finally {
  }
}

function* getCompositeId({ payload }) {
  try {
    const data = yield call(manufactureService.getCompositeId, payload);

    yield put(getCompositeIdSuccess(data));
  } catch (error) {
    yield put(getCompositeIdError(error));
  } finally {
  }
}

function* deletemanufacturingItem({ payload }) {
  try {
    const data = yield call(manufactureService.deletemanufacturingItem, payload);

    yield put(deletemanufacturingItemSuccess(data));
  } catch (error) {
    yield put(deletemanufacturingItemError(error));
  } finally {
  }
}

function* updatemanufacturingItem({ payload }) {
  try {
    const data = yield call(manufactureService.updatemanufacturingItem, payload);

    yield put(updatemanufacturingItemSuccess(data));
  } catch (error) {
    yield put(updatemanufacturingItemError(error));
  } finally {
  }
}

function* saveMrItemStock({ payload }) {
  try {
    const data = yield call(manufactureService.saveMrItemStock, payload);

    yield put(saveMrItemStockSuccess(data));
  } catch (error) {
    yield put(saveMrItemStockError(error));
  } finally {
  }
}

function* listMrItemStock({ payload }) {
  try {
    const data = yield call(manufactureService.listMrItemStock, payload);

    yield put(listMrItemStockSuccess(data));
  } catch (error) {
    yield put(listMrItemStockError(error));
  } finally {
  }
}

function* updateMrItemStock({ payload }) {
  try {
    const data = yield call(manufactureService.updateMrItemStock, payload);

    yield put(updateMrItemStockSuccess(data));
  } catch (error) {
    yield put(updateMrItemStockError(error));
  } finally {
  }
}

function* deleteMrItemStock({ payload }) {
  try {
    const data = yield call(manufactureService.deleteMrItemStock, payload);

    yield put(deleteMrItemStockSuccess(data));
  } catch (error) {
    yield put(deleteMrItemStockError(error));
  } finally {
  }
}

function* listCompany({ payload }) {
  try {
    const data = yield call(manufactureService.listCompany, payload);

    yield put(listCompanySuccess(data));
  } catch (error) {
    yield put(listCompanyError(error));
  } finally {
  }
}

function* listContact({ payload }) {
  try {
    const data = yield call(manufactureService.listContact, payload);

    yield put(listContactSuccess(data));
  } catch (error) {
    yield put(listContactError(error));
  } finally {
  }
}

function* listSalesman({ payload }) {
  try {
    const data = yield call(manufactureService.listSalesman, payload);

    yield put(listSalesmanSuccess(data));
  } catch (error) {
    yield put(listSalesmanError(error));
  } finally {
  }
}

function* saveManufacturingRequest({ payload }) {
  try {
    const data = yield call(manufactureService.saveManufacturingRequest, payload);

    yield put(saveManufacturingRequestSuccess(data));
  } catch (error) {
    yield put(saveManufacturingRequestError(error));
  } finally {
  }
}

function* listManufacturingRequest({ payload }) {
  try {
    const data = yield call(manufactureService.listManufacturingRequest, payload);
    Array.isArray(data) ?
    yield put(listManufacturingRequestSuccess(data)) : yield put(listManufacturingRequestError([]));
  } catch (error) {
    yield put(listManufacturingRequestError(error));
  } finally {
  }
}


function* updateManufacturingRequest({ payload }) {
  try {
    const data = yield call(manufactureService.updateManufacturingRequest, payload);

    yield put(updateManufacturingRequestSuccess(data));
  } catch (error) {
    yield put(updateManufacturingRequestError(error));
  } finally {
  }
}

function* getManufactureItem({ payload }) {
  try {
    const data = yield call(manufactureService.getManufactureItem, payload);

    yield put(getManufactureItemSuccess(data));
  } catch (error) {
    yield put(getManufactureItemError(error));
  } finally {
  }
}


function* deleteManufactureItem({ payload }) {
  try {
    const data = yield call(manufactureService.deleteManufactureItem, payload);

    yield put(deleteManufactureItemSuccess(data));
  } catch (error) {
    yield put(deleteManufactureItemError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.LIST_ITEMS, listItems)]);
  yield all([takeEvery(actionTypes.SAVE_MANUFACTURE_ITEMS, saveManufactureItems)]);
  yield all([takeEvery(actionTypes.LIST_MANUFACTURE_ITEMS, listManufactureItems)]);
  yield all([takeEvery(actionTypes.GET_COMPOSITE_ID, getCompositeId)]);
  yield all([takeEvery(actionTypes.DELETE_MANUFACTURING_ITEM, deletemanufacturingItem)]);
  yield all([takeEvery(actionTypes.UPDATE_MANUFACTURING_ITEM, updatemanufacturingItem)]);

  yield all([takeEvery(actionTypes.SAVE_MRITEM_STOCK, saveMrItemStock)]);
  yield all([takeEvery(actionTypes.LIST_MRITEM_STOCK, listMrItemStock)]);
  yield all([takeEvery(actionTypes.UPDATE_MRITEM_STOCK, updateMrItemStock)]);
  yield all([takeEvery(actionTypes.DELETE_MRITEM_STOCK, deleteMrItemStock)]);

  yield all([takeEvery(actionTypes.LIST_COMPANY, listCompany)]);
  yield all([takeEvery(actionTypes.LIST_CONTACT, listContact)]);
  yield all([takeEvery(actionTypes.LIST_SALESMAN, listSalesman)]);
  yield all([takeEvery(actionTypes.SAVE_MANUFACTURING_REQUEST, saveManufacturingRequest)]);
  yield all([takeEvery(actionTypes.LIST_MANUFACTURING_REQUEST, listManufacturingRequest)]);
  yield all([takeEvery(actionTypes.UPDATE_MANUFACTURING_REQUEST, updateManufacturingRequest)]);
  yield all([takeEvery(actionTypes.GET_MANUFACTURE_ITEMS, getManufactureItem)]);
  yield all([takeEvery(actionTypes.DELETE_MANUFACTURE_ITEMS, deleteManufactureItem)]);
}