import axios from '@service/ApiClient'
// import { siteUrl } from './config'

class mrvService {
    constructor(callback) {
        this.callback = callback
    }

    async listPo(payload) {
        return await axios.post('Inventory/Mrv/list_purchaseorder_in_mrv', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchPoById(payload) {
        return await axios.post('Inventory/Mrv/get_po_items_by_poid', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchMrvById(payload) {
        return await axios.post('Inventory/Mrv/list_mrv_by_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async checkBarcode(payload) {
        return await axios.post('Inventory/Mrv/barcode_checking_in_mrv', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listStoreMrv(payload) {
        return await axios.post('Inventory/Mrv/list_stores', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async saveMrv(payload) {
        return await axios.post('Inventory/Mrv/save_mrv', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listMrv(payload) {
        return await axios.post('Inventory/Mrv/list_mrv', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }


}

export default new mrvService();