import axios from '@service/ApiClient'

class userservice {
    constructor(callback) {
        this.callback = callback
    }
    async jobDepartment(payload) {
        return await axios.post('/Crm/User/list_department',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async jobDesignation(payload) {
        return await axios.post('/Crm/User/list_designation',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }   

    async jobRoles(payload) {
        return await axios.post('/Crm/User/list_roles',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async saveUser(payload) {
        return await axios.post('/Crm/User/save_user',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listUser(payload) {
        return await axios.post('/Crm/User/list_user',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateUser(payload) {
        return await axios.post('/Crm/User/update_user',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteUser(payload) {
        return await axios.post('/Crm/User/delete_user',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async filterUser(payload) {
        return await axios.post('/Crm/User/filter_user_details',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async getUserDetails(payload) {
        return await axios.post('/Crm/User/user_overview',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async getRemunerationDetails(payload) {
        return await axios.post('/Crm/User/get_reneumaration_details',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async insertRemunerationPackage(payload) {
        return await axios.post('/Crm/User/insert_remuneration_pakge',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async emailPhoneValidation(payload) {
        return await axios.post('/Crm/User/email_phone_validation',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new userservice();