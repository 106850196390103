export function handleLogin(payload) {
  return { type: 'LOGIN', payload };
}

export function handleLoginSuccess(data) {
  return { type: 'LOGIN_SUCCESS', data };
}

export function handleLoginError(error) {
  return { type: 'LOGIN_ERROR', error };
}

export function handleLogout() {
  return { type: 'LOGOUT' };
}

export function handleLogoutSuccess() {
  return { type: 'LOGOUT_SUCCESS' };
}

export function handleLogoutError() {
  return { type: 'LOGOUT_ERROR' };
}

export function handleSignUp(payload) {
  return { type: 'SIGNUP', payload };
}
export function handleSignUpSuccess(data) {
  return { type: 'SIGNUP_SUCCESS', data };
}

export function handleSignUpError(error) {
  return { type: 'SIGNUP_ERROR', error };
}

export function handleClearData() {
  return { type: 'CLEAR_DATA' };
}
export function setSignUp() {
  return { type: 'SET_SIGNUP' };
}

export function verifyEmailAuth(payload) {
  return { type: 'VERIFY_EMAIL_AUTH', payload };
}
export function verifyEmailAuthSuccess(data) {
  return { type: 'VERIFY_EMAIL_AUTH_SUCCESS', data };
}
export function verifyEmailAuthError(error) {
  return { type: 'VERIFY_EMAIL_AUTH_ERROR', error };
}

export function verifyUserEmailAuth(payload) {
  return { type: 'VERIFY_USER_EMAIL_AUTH', payload };
}
export function verifyUserEmailAuthSuccess(data) {
  return { type: 'VERIFY_USER_EMAIL_AUTH_SUCCESS', data };
}
export function verifyUserEmailAuthError(error) {
  return { type: 'VERIFY_USER_EMAIL_AUTH_ERROR', error };
}

export function saveCredential(payload) {
  return { type: 'SAVE_CREDENTIAL', payload };
}
export function saveCredentialSuccess(data) {
  return { type: 'SAVE_CREDENTIAL_SUCCESS', data };
}
export function saveCredentialError(error) {
  return { type: 'SAVE_CREDENTIAL_ERROR', error };
}

export function getMenuLists(payload) {
  return { type: 'GET_MENU_LISTS', payload };
}
export function getMenuListsSuccess(data) {
  return { type: 'GET_MENU_LISTS_SUCCESS', data };
}
export function getMenuListsError(error) {
  return { type: 'GET_MENU_LISTS_ERROR', error };
}

export function updateLogo(data) {
  return { type: 'UPDATE_LOGO_SETTING', data };
}