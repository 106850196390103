import axios from '@service/ApiClient'
class equipmentMasterService {
    constructor(callback) {
        this.callback = callback
    }
    async saveEquipmentMaster(payload) {
        return await axios.post('/Equipment/Equipment/equipment_master_save', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listEquipmentMaster(payload) {
        return await axios.post('/Equipment/Equipment/equipment_master_list', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateEquipmentMaster(payload) {
        return await axios.post('/Equipment/Equipment/equipment_master_update', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteEquipmentMaster(payload) {
        return await axios.post('/Equipment/Equipment/equipment_master_delete', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

}

export default new equipmentMasterService();