import ApiClient from '../../ApiClient'

class roleService {
    constructor(callback) {
        this.callback = callback
    }

    async createRole(payload) {
        const response = await ApiClient.post(
            'Organization/Assignroles/save_roles',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchRole(payload) {
        const response = await ApiClient.post(
            'Organization/Assignroles/list_roles',
            payload,
        ).then((response) => {
            console.log(response.data)
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }


    async updateRole(payload) {
        const response = await ApiClient.post(
            'Organization/Assignroles/update_roles',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteRole(payload) {
        const response = await ApiClient.post(
            'Organization/Assignroles/delete_roles',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchAssignedRoleMember(payload) {
        const response = await ApiClient.post(
            'Organization/Assignroles/view_assigned_member',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async deleteAssignedRoleMember(payload) {
        const response = await ApiClient.post(
            'Organization/Assignroles/remove_assign_member',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchPermissionDetails(payload) {
        const response = await ApiClient.post(
            'Organization/Assignroles/set_permission',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async setPermission(payload) {
        const response = await ApiClient.post(
            'Organization/Assignroles/role_permission_set',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new roleService();