
export const actionTypes = {
    SAVE_ITEM: 'SAVE_ITEM',
    SAVE_ITEM_SUCCESS: 'SAVE_ITEM_SUCCESS',
    SAVE_ITEM_ERROR: 'SAVE_ITEM_ERROR',

    LIST_ITEMS: 'LIST_ITEMS',
    LIST_ITEMS_SUCCESS: 'LIST_ITEMS_SUCCESS',
    LIST_ITEMS_ERROR: 'LIST_ITEMS_ERROR',

    UPDATE_ITEM: 'UPDATE_ITEM',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_ERROR: 'UPDATE_ITEM_ERROR',

    DELETE_ITEM: 'DELETE_ITEM',
    DELETE_ITEM_SUCCESS: 'DELETE_ITEM_SUCCESS',
    DELETE_ITEM_ERROR: 'DELETE_ITEM_ERROR',

    LIST_ITEM_CATEGORY: 'LIST_ITEM_CATEGORY',
    LIST_ITEM_CATEGORY_SUCCESS: 'LIST_ITEM_CATEGORY_SUCCESS',
    LIST_ITEM_CATEGORY_ERROR: 'LIST_ITEM_CATEGORY_ERROR',

    LIST_ITEM_SUBCATEGORY: 'LIST_ITEM_SUBCATEGORY',
    LIST_ITEM_SUBCATEGORY_SUCCESS: 'LIST_ITEM_SUBCATEGORY_SUCCESS',
    LIST_ITEM_SUBCATEGORY_ERROR: 'LIST_ITEM_SUBCATEGORY_ERROR',

    LIST_ITEM_BRAND: 'LIST_ITEM_BRAND',
    LIST_ITEM_BRAND_SUCCESS: 'LIST_ITEM_BRAND_SUCCESS',
    LIST_ITEM_BRAND_ERROR: 'LIST_ITEM_BRAND_ERROR',

    FETCH_ITEM_BY_ID: 'FETCH_ITEM_BY_ID',
    FETCH_ITEM_BY_ID_SUCCESS: 'FETCH_ITEM_BY_ID_SUCCESS',
    FETCH_ITEM_BY_ID_ERROR: 'FETCH_ITEM_BY_ID_ERROR',
}

export const saveItem = (payload) => {
    return {
        type: actionTypes.SAVE_ITEM, payload
    };
};

export const saveItemSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_ITEM_SUCCESS,
        payload
    };
};

export const saveItemError = (payload) => {
    return {
        type: actionTypes.SAVE_ITEM_ERROR,
        payload
    };
};

export const listItem = (payload) => {

    return {
        type: actionTypes.LIST_ITEMS, payload
    };
};

export const listItemSuccess = (payload) => {
    return {
        type: actionTypes.LIST_ITEMS_SUCCESS,
        payload
    };
};

export const listItemError = (payload) => {
    return {
        type: actionTypes.LIST_ITEMS_ERROR,
        payload
    };
};

export const updateItem = (payload) => {
    return {
        type: actionTypes.UPDATE_ITEM, payload
    };
};

export const updateItemSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_ITEM_SUCCESS,
        payload
    };
};

export const updateItemError = (payload) => {
    return {
        type: actionTypes.UPDATE_ITEM_ERROR,
        payload
    };
};

export const deleteItem = (payload) => {
    return {
        type: actionTypes.DELETE_ITEM, payload
    };
};
export const deleteItemSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_ITEM_SUCCESS,
        payload
    };
};

export const deleteItemError = (payload) => {
    return {
        type: actionTypes.DELETE_ITEM_ERROR,
        payload
    };
};

export const listCategory = (payload) => {
    return {
        type: 'LIST_ITEM_CATEGORY', payload
    };
};
export const listCategorySuccess = (payload) => {
    return {
        type: 'LIST_ITEM_CATEGORY_SUCCESS',
        payload
    };
};
export const listCategoryError = (payload) => {
    return {
        type: 'LIST_ITEM_CATEGORY_ERROR',
        payload
    };
};

export const listSubCategory = (payload) => {
    return {
        type: 'LIST_ITEM_SUBCATEGORY',
        payload
    };
};
export const listSubCategorySuccess = (payload) => {
    return {
        type: 'LIST_ITEM_SUBCATEGORY_SUCCESS',
        payload
    };
};
export const listSubCategoryError = (payload) => {
    return {
        type: 'LIST_ITEM_SUBCATEGORY_ERROR',
        payload
    };
};

export const listBrand = (payload) => {
    return {
        type: 'LIST_ITEM_BRAND',
        payload
    };
};
export const listBrandSuccess = (payload) => {
    return {
        type: 'LIST_ITEM_BRAND_SUCCESS',
        payload
    };
};
export const listBrandError = (payload) => {
    return {
        type: 'LIST_ITEM_BRAND_ERROR',
        payload
    };
};


export const fetchItemByID = (payload) => {
    return {
        type: actionTypes.FETCH_ITEM_BY_ID, payload
    };
};

export const fetchItemByIDSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ITEM_BY_ID_SUCCESS,
        payload
    };
};

export const fetchItemByIDError = (payload) => {
    return {
        type: actionTypes.FETCH_ITEM_BY_ID_ERROR,
        payload
    };
};
