module.exports = {
    // siteUrl: 'https://bixss.bixsspro.com/manage/index.php/',
    // baseUrl: 'https://bixss.bixsspro.com/',
    // imageUrl: 'https://bixss.bixsspro.com/images/'

    siteUrl: 'https://mstage.bixss.com/manage/index.php/',
    baseUrl: 'https://mstage.bixss.com/manage/',
    imageUrl: 'https://mstage.bixss.com/manage/images/'

    // siteUrl: 'https://bixss.com/manage/index.php/',
    // baseUrl: 'https://bixss.com/',
    // imageUrl: 'https://bixss.com/manage/images/'

    // siteUrl: `http://localhost/bixsssasrepo/index.php/`,
    // baseUrl: 'http://localhost/bixsssasrepo/',
    // imageUrl: 'http://localhost/bixsssasrepo/images/'
    
    
};