import axios from '../../ApiClient'
// import { siteUrl } from './config'

class designationservice {
    constructor(callback) {
        this.callback = callback
    }

    async fetch_designation(payload) {
        return await axios.post('/Organization/Designation/list_designation',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async save_designation(payload) {

        return await axios.post('/Organization/Designation/save_designation',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async delete_designation(payload) {
        return await axios.post('/Organization/Designation/delete_designation',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async edit_designation(payload) {
        return await axios.post('/Organization/Designation/edit_designation',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async update_designation(payload) {
        return await axios.post('/Organization/Designation/update_designation',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async AddDesignationRating(payload) {
        return await axios.post('/Organization/Designation/save_rate',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async UpdateDesignationRating(payload) {
        return await axios.post('/Organization/Designation/update_rate',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new designationservice();