// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { store } from './redux/storeConfig/store'

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
import { ToastContainer } from 'react-toastify'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from './utility/context/ThemeColors'
import { IntlProviderWrapper } from './utility/context/Internationalization'

// ** Spinner (Splash Screen)
import Spinner from '@src/views/common/spinner'
// import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** Fake Database
import './@fake-db'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
// import './assets/scss/commonStyle.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

const persistor = persistStore(store);

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

// localStorage.setItem('userData', JSON.stringify({"id":1,"fullName":"John Doe","username":"johndoe","avatar":"/static/media/avatar-s-11.1d46cc62.jpg","email":"admin@demo.com","role":"admin","ability":[{"action":"manage","subject":"all"}],"extras":{"eCommerceCartItemsCount":5},"accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjEzMzgzNTk4LCJleHAiOjE2MTMzODQxOTh9.IQsTrGqdDecx0juOxdhCH8WOtPHPvcLswXtbB3X5Fcw","refreshToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjEzMzgzNTk4LCJleHAiOjE2MTMzODQxOTh9.WrKfnMJiyOXXohyXDaVoiHO8CekmaGZ7Y-wj6eEd_Ic"}))
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Suspense fallback={<Spinner showLoader={true} />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <IntlProviderWrapper>
              <LazyApp />
              <ToastContainer newestOnTop />
            </IntlProviderWrapper>
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
