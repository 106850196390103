import axios from '@service/ApiClient'
// import { siteUrl } from './config'

class vendorService {
    constructor(callback) {
        this.callback = callback
    }
    async saveVendor(payload) {
        return await axios.post('/Inventory/Vendor/save_vendor', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listVendor(payload) {
        return await axios.post('/Inventory/Vendor/list_vendors', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateVendor(payload) {
        return await axios.post('/Inventory/Vendor/update_vendor', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteVendor(payload) {
        return await axios.post('/Inventory/Vendor/vendor_delete', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new vendorService();