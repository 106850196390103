
export const actionTypes = {
    SAVE_EQUIPMENT_MASTER: 'SAVE_EQUIPMENT_MASTER',
    SAVE_EQUIPMENT_MASTER_SUCCESS: 'SAVE_EQUIPMENT_MASTER_SUCCESS',
    SAVE_EQUIPMENT_MASTER_ERROR: 'SAVE_EQUIPMENT_MASTER_ERROR',
    LIST_EQUIPMENT_MASTER: 'LIST_EQUIPMENT_MASTER',
    LIST_EQUIPMENT_MASTER_SUCCESS: 'LIST_EQUIPMENT_MASTER_SUCCESS',
    LIST_EQUIPMENT_MASTER_ERROR: 'LIST_EQUIPMENT_MASTER_ERROR',
    UPDATE_EQUIPMENT_MASTER: 'UPDATE_EQUIPMENT_MASTER',
    UPDATE_EQUIPMENT_MASTER_SUCCESS: 'UPDATE_EQUIPMENT_MASTER_SUCCESS',
    UPDATE_EQUIPMENT_MASTER_ERROR: 'UPDATE_EQUIPMENT_MASTER_ERROR',
    DELETE_EQUIPMENT_MASTER: 'DELETE_EQUIPMENT_MASTER',
    DELETE_EQUIPMENT_MASTER_SUCCESS: 'DELETE_EQUIPMENT_MASTER_SUCCESS',
    DELETE_EQUIPMENT_MASTER_ERROR: 'DELETE_EQUIPMENT_MASTER_ERROR'
};

export const saveEquipmentMaster = (payload) => {
    return {
        type: actionTypes.SAVE_EQUIPMENT_MASTER, payload
    };
};
export const saveEquipmentMasterSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_EQUIPMENT_MASTER_SUCCESS,
        payload
    };
};
export const saveEquipmentMasterError = (payload) => {
    return {
        type: actionTypes.SAVE_EQUIPMENT_MASTER_ERROR,
        payload
    };
};

export const listEquipmentMaster = (payload) => {
    return {
        type: actionTypes.LIST_EQUIPMENT_MASTER, payload
    };
};
export const listEquipmentMasterSuccess = (payload) => {
    return {
        type: actionTypes.LIST_EQUIPMENT_MASTER_SUCCESS,
        payload
    };
};
export const listEquipmentMasterError = (payload) => {
    return {
        type: actionTypes.LIST_EQUIPMENT_MASTER_ERROR,
        payload
    };
};

export const updateEquipmentMaster = (payload) => {
    return {
        type: actionTypes.UPDATE_EQUIPMENT_MASTER, payload
    };
};
export const updateEquipmentMasterSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_EQUIPMENT_MASTER_SUCCESS,
        payload
    };
};
export const updateEquipmentMasterError = (payload) => {
    return {
        type: actionTypes.UPDATE_EQUIPMENT_MASTER_ERROR,
        payload
    };
};

export const deleteEquipmentMaster = (payload) => {
    return {
        type: actionTypes.DELETE_EQUIPMENT_MASTER, payload
    };
};
export const deleteEquipmentMasterSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_EQUIPMENT_MASTER_SUCCESS,
        payload
    };
};
export const deleteEquipmentMasterError = (payload) => {
    return {
        type: actionTypes.DELETE_EQUIPMENT_MASTER_ERROR,
        payload
    };
};