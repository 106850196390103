import axios from '../../ApiClient'
// import { siteUrl } from './config'

class manufactureservice {
    constructor(callback) {
        this.callback = callback
    }

    async addManufacture(payload) {
        try {
            return await axios.post('Inventory/Manufacturer/add_manufacturer', payload,
            ).then((response) => {
                return response.data
            }).catch((error) => ({ error: JSON.stringify(error) }));
        } catch (error) {

        }

    }
    async fetchManufacture(payload) {
        try {
            return await axios.post('Inventory/Manufacturer/list_manufacturer', payload,
            ).then((response) => {
                console.log(response.data)
                return response.data
            }).catch((error) => ({ error: JSON.stringify(error) }));
        } catch (error) {
            console.log(error);
        }
    }
    async editManufacture(payload) {
        return await axios.post('Inventory/Manufacturer/update_manufacturer', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));

    }
    async deleteManufacture(payload) {
        return await axios.post('Inventory/Manufacturer/delete_manufacturer', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));

    }



    async listItems(payload) {
        return await axios.post('/Inventory/Item/list_items', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async saveManufactureItems(payload) {
        return await axios.post('/Manufacture/Items/save_manufacture_items', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listManufactureItems(payload) {
        return await axios.post('/Manufacture/Items/list_manufacture_items', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async getCompositeId(payload) {
        return await axios.post('/Manufacture/Items/get_composite_items', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deletemanufacturingItem(payload) {
        return await axios.post('/Manufacture/Items/delete_manufacture_items', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updatemanufacturingItem(payload) {
        return await axios.post('/Manufacture/Items/update_manufacture_items', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }


    async saveMrItemStock(payload) {
        return await axios.post('/Manufacture/Manufacture_item_stock/save_manufacture_item_stock', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listMrItemStock(payload) {
        return await axios.post('/Manufacture/Manufacture_item_stock/list_manufacture_stockitems', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateMrItemStock(payload) {
        return await axios.post('/Manufacture/Manufacture_item_stock/update_manufacture_items', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteMrItemStock(payload) {
        return await axios.post('/Manufacture/Manufacture_item_stock/delete_manufacture_details_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }


    async listCompany(payload) {
        return await axios.post('/Crm/Company/list_companies', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listContact(payload) {
        return await axios.post('/Crm/Contact/list_contacts', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listSalesman(payload) {
        return await axios.post('/Crm/User/list_user', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async saveManufacturingRequest(payload) {
        return await axios.post('/Manufacture/Manufacture_request/save_manufacture_request', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listManufacturingRequest(payload) {
        return await axios.post('/Manufacture/Manufacture_request/list_manufacture_items', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateManufacturingRequest(payload) {
        return await axios.post('/Manufacture/Manufacture_request/update_manufacture_items', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async getManufactureItem(payload) {
        return await axios.post('/Manufacture/Manufacture_request/get_manufacture_details_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteManufactureItem(payload) {
        return await axios.post('/Manufacture/Manufacture_request/delete_manufacture_details_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new manufactureservice();

